export const rssFeeds = [
  // Linux Feeds
  {
    name: 'Linux Foundation Blog',
    url: 'https://www.linuxfoundation.org/feed',
    category: 'linux',
    defaultImages: [
      'https://images.unsplash.com/photo-1629654297299-c8506221ca97',
      'https://images.unsplash.com/photo-1557853197-aefb550b6fdc',
      'https://images.unsplash.com/photo-1618477247222-acbdb0e159b3',
      'https://images.unsplash.com/photo-1714846201700-35b42d937158',
      'https://images.unsplash.com/photo-1714846201670-1c5721196c7a'
    ]
  },
  {
    name: 'OMG Ubuntu',
    url: 'https://www.omgubuntu.co.uk/feed',
    category: 'linux',
    defaultImages: [
      'https://images.unsplash.com/photo-1629654297299-c8506221ca97',
      'https://images.unsplash.com/photo-1557853197-aefb550b6fdc',
      'https://images.unsplash.com/photo-1618477247222-acbdb0e159b3'

    ]
  },
  {
    name: 'It\'s FOSS',
    url: 'https://itsfoss.com/feed/',
    category: 'linux',
    defaultImages: [
      'https://images.unsplash.com/photo-1629654297299-c8506221ca97',
      'https://images.unsplash.com/photo-1557853197-aefb550b6fdc',
      'https://images.unsplash.com/photo-1618477247222-acbdb0e159b3'
    ]
  },
  // Quantum Computing Feeds
  {
    name: 'Quantum Computing Report',
    url: 'https://quantumcomputingreport.com/feed/',
    category: 'quantum',
    defaultImages: [
      'https://images.unsplash.com/photo-1635070041078-e363dbe005cb',
      'https://images.unsplash.com/photo-1628595351029-c2bf17511435',
      'https://images.unsplash.com/photo-1517976487492-5750f3195933',
      'https://images.unsplash.com/photo-1666112835145-d79fc3f2e008'
    ]
  },
  {
    name: 'Quantum Zeitgeist',
    url: 'https://quantumzeitgeist.com/feed/',
    category: 'quantum',
    defaultImages: [
      'https://images.unsplash.com/photo-1635070041078-e363dbe005cb',
      'https://images.unsplash.com/photo-1628595351029-c2bf17511435',
      'https://images.unsplash.com/photo-1517976487492-5750f3195933'
    ]
  },
  // Tech Feeds
  {
    name: 'TechCrunch',
    url: 'https://techcrunch.com/feed/',
    category: 'tech',
    defaultImages: [
      'https://images.unsplash.com/photo-1485827404703-89b55fcc595e',
      'https://images.unsplash.com/photo-1677442136019-21780ecad995',
      'https://images.unsplash.com/photo-1620712943543-bcc4688e7485',
      'https://images.unsplash.com/photo-1483478550801-ceba5fe50e8e',
      'https://images.unsplash.com/photo-1488590528505-98d2b5aba04b',
      'https://images.unsplash.com/photo-1451187863213-d1bcbaae3fa3',
      'https://images.unsplash.com/photo-1704871132518-94cadb901021'
    ]
  },
  {
    name: 'TechDirt',
    url: 'https://www.techdirt.com/feed/',
    category: 'tech',
    defaultImages: [
      'https://images.unsplash.com/photo-1485827404703-89b55fcc595e',
      'https://images.unsplash.com/photo-1677442136019-21780ecad995',
      'https://images.unsplash.com/photo-1620712943543-bcc4688e7485'
    ]
  }
];
