import { rssFeeds } from '../config/rssFeeds';

export interface NewsItem {
  title: string;
  description: string;
  link: string;
  date: string;
  source: string;
  imageUrl: string;
  category: string;
}

class FeedCache {
  private static instance: FeedCache;
  private cache: NewsItem[] = [];
  private lastUpdate: number = 0;
  private readonly UPDATE_INTERVAL = 900000; // 15 minutes
  private readonly MAX_AGE = 2592000000; // 30 days
  private readonly CORS_PROXIES = [
    'https://api.allorigins.win/raw?url=',
    'https://corsproxy.io/?',
    'https://cors.eu.org/'
  ];

  private constructor() {
    this.updateCache();
  }

  public static getInstance(): FeedCache {
    if (!FeedCache.instance) {
      FeedCache.instance = new FeedCache();
    }
    return FeedCache.instance;
  }

  private getRandomImage(category: string): string {
    const feed = rssFeeds.find(f => f.category === category);
    if (!feed?.defaultImages.length) {
      return 'https://images.unsplash.com/photo-1635070041078-e363dbe005cb';
    }
    return feed.defaultImages[Math.floor(Math.random() * feed.defaultImages.length)];
  }

  private formatDate(dateStr: string): string {
    try {
      const date = new Date(dateStr);
      if (isNaN(date.getTime())) {
        return new Date().toLocaleDateString();
      }
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return new Date().toLocaleDateString();
    }
  }

  private async fetchWithRetry(url: string, attempts = 0): Promise<Response> {
    if (attempts >= this.CORS_PROXIES.length) {
      throw new Error(`Failed to fetch ${url} after trying all CORS proxies`);
    }

    try {
      const proxyUrl = this.CORS_PROXIES[attempts] + encodeURIComponent(url);
      const response = await fetch(proxyUrl, {
        headers: {
          'Accept': 'application/rss+xml, application/xml, text/xml, application/atom+xml',
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      return response;
    } catch (error) {
      console.warn(`CORS proxy ${attempts + 1} failed for ${url}:`, error);
      return this.fetchWithRetry(url, attempts + 1);
    }
  }

  private async fetchFeeds(): Promise<NewsItem[]> {
    try {
      const fetchPromises = rssFeeds.map(async (feed) => {
        try {
          const response = await this.fetchWithRetry(feed.url);
          const data = await response.text();
          
          if (!data || data.trim().length === 0) {
            throw new Error('Empty response received');
          }

          const parser = new DOMParser();
          const xml = parser.parseFromString(data, 'text/xml');
          
          if (xml.querySelector('parsererror')) {
            throw new Error('XML parsing error');
          }

          const items = Array.from(xml.querySelectorAll('item, entry'));
          
          if (items.length === 0) {
            console.warn(`No items found in feed: ${feed.name}`);
          }

          return Promise.all(items.map(item => this.parseRSSItem(item, feed)))
            .then(results => results.filter((item): item is NewsItem => item !== null));
        } catch (error) {
          console.error(`Error fetching ${feed.name}:`, error);
          return [];
        }
      });

      const allItems = (await Promise.all(fetchPromises)).flat();
      
      if (allItems.length === 0) {
        console.warn('No items fetched from any feed');
      }

      return allItems.sort((a, b) => 
        new Date(b.date).getTime() - new Date(a.date).getTime()
      );
    } catch (error) {
      console.error('Error fetching feeds:', error);
      return [];
    }
  }

  private parseRSSItem(item: Element, feed: typeof rssFeeds[0]): NewsItem | null {
    try {
      // Get link
      let link = '';
      const linkElement = item.querySelector('link');
      
      if (linkElement) {
        link = linkElement.getAttribute('href') || linkElement.textContent || '';
        // Clean CDATA if present
        const cdataMatch = link.match(/<!\[CDATA\[(.*?)\]\]>/);
        if (cdataMatch) {
          link = cdataMatch[1].trim();
        }
      }

      if (!link || link.split('/').length <= 4) {
        console.warn(`Invalid link found in ${feed.name}`);
        return null;
      }

      // Get publication date
      const pubDateElement = item.querySelector('pubDate, published, updated, dc\\:date');
      const pubDate = pubDateElement ? this.formatDate(pubDateElement.textContent || '') : '';
      
      if (!pubDate) {
        console.warn(`No valid date found in ${feed.name}`);
        return null;
      }

      // Get title
      const title = item.querySelector('title')?.textContent?.trim() || '';
      if (!title) {
        console.warn(`No title found in ${feed.name}`);
        return null;
      }

      // Get description
      let description = '';
      const descElement = item.querySelector('description, summary, content\\:encoded');
      if (descElement) {
        description = descElement.textContent || '';
        description = description
          .replace(/<!\[CDATA\[(.*?)\]\]>/gs, '$1')
          .replace(/<[^>]+>/g, '')
          .trim();
        
        if (description.length > 200) {
          description = description.substring(0, 197) + '...';
        }
      }

      // Get image
      let imageUrl = this.getRandomImage(feed.category);
      
      // Try multiple image sources
      const possibleImageSources = [
        item.querySelector('content\\:encoded, content, description')?.textContent,
        item.querySelector('media\\:content, media\\:thumbnail')?.getAttribute('url'),
        item.querySelector('enclosure[type^="image"]')?.getAttribute('url'),
        item.querySelector('image url')?.textContent
      ];

      for (const source of possibleImageSources) {
        if (source) {
          const imgMatch = source.match(/<img[^>]+src=["']([^"']+)["']/);
          if (imgMatch && imgMatch[1]) {
            imageUrl = imgMatch[1];
            break;
          } else if (source.match(/^https?:\/\/.*\.(jpg|jpeg|png|gif|webp)/i)) {
            imageUrl = source;
            break;
          }
        }
      }

      return {
        title,
        description,
        link,
        date: pubDate,
        source: feed.name,
        category: feed.category,
        imageUrl
      };
    } catch (error) {
      console.error(`Error parsing RSS item from ${feed.name}:`, error);
      return null;
    }
  }

  private async updateCache(): Promise<void> {
    const now = Date.now();
    if (now - this.lastUpdate >= this.UPDATE_INTERVAL) {
      const newItems = await this.fetchFeeds();
      if (newItems.length > 0) {
        this.cache = newItems;
        this.lastUpdate = now;
      }
    }
  }

  public async getNews(): Promise<NewsItem[]> {
    await this.updateCache();
    return this.cache;
  }
}

export default FeedCache;