import React from 'react';
import RSSFeed from '../components/RSSFeed';
import PageTitle from '../components/PageTitle';
import Logo from '../components/Logo';

function Home() {
  return (
    <>
      <PageTitle 
        title="Welcome"
        description="Explore quantum computing, Linux, and cutting-edge technology with Quantumbytz. Stay updated with the latest breakthroughs and innovations."
        keywords="quantum computing, Linux, technology, tech news, innovation, software development"
      />
      
      {/* Hero Section */}
      <div className="bg-[#232323] text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <Logo className="w-32 h-32 mx-auto mb-6" />
            <h1 className="text-4xl md:text-6xl font-bold mb-4">
              Welcome to <span className="text-[#EC6224]">QuantumBytz</span>
            </h1>
            <p className="text-xl text-gray-300 max-w-2xl mx-auto">
              Your gateway to the future of technology. Explore the latest in quantum computing,
              Linux innovations, and cutting-edge tech developments.
            </p>
          </div>
        </div>
      </div>

      {/* News Feed Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h2 className="text-3xl font-bold text-[#232323] mb-8">
          Latest in Tech
        </h2>
        <RSSFeed />
      </div>

      {/* Features Section */}
      <div className="bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-[#232323] mb-3">
                Quantum Updates
              </h3>
              <p className="text-gray-600">
                Stay at the forefront of quantum computing breakthroughs, research developments,
                and practical applications shaping our technological future.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-[#232323] mb-3">
                Linux Expertise
              </h3>
              <p className="text-gray-600">
                Dive deep into Linux systems, from kernel developments to enterprise solutions,
                security hardening, and performance optimization techniques.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-[#232323] mb-3">
                Tech Insights
              </h3>
              <p className="text-gray-600">
                Comprehensive analysis of emerging technologies, development practices,
                and their impact on the future of computing and software development.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;